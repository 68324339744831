// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports other styles in your app.
@import 'libs/shared/styles/src/palettes/mat-light-palette.scss';
@import 'libs/shared/styles/src/palettes/mat-dark-palette.scss';
@import 'libs/shared/styles/src/variables/component-variables.scss';
@import 'libs/shared/styles/src/variables/table-variables.scss';
@import 'libs/shared/styles/src/variables/typo-variables.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// $general-primary: mat.define-palette(mat.$indigo-palette);
// $general-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $general-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

// $general-theme: mat.define-light-theme((
//   color: (
//     primary: $general-primary,
//     accent: $general-accent,
//     warn: $general-warn,
//   )
// ));

// Define a light theme
$custom-light-primary: mat.define-palette($light-primary);
$custom-light-accent: mat.define-palette($light-accent);
$custom-light-warn: mat.define-palette($light-warn);
$custom-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-light-primary,
      accent: $custom-light-accent,
      warn: $custom-light-warn,
    ),
  )
);

// Define a dark theme
$custom-dark-primary: mat.define-palette($dark-primary);
$custom-dark-accent: mat.define-palette($dark-accent);
$custom-dark-warn: mat.define-palette(mat.$red-palette);
$custom-dark-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-dark-primary,
      accent: $custom-dark-accent,
      warn: $custom-dark-warn,
    ),
  )
);

$custom-typography: mat.define-typography-config(
  $font-family: '"DM Sans", sans-serif',
);

// $custom-theme: mat.define-light-theme(
//   (
//     typography: $custom-typography,
//   )
// );

@include mat.core($custom-typography);

// Apply the light theme by default
@include mat.core-theme($custom-light-theme);
@include mat.all-component-themes($custom-light-theme);

// Apply the dark theme only when the `.custom-dark-theme` CSS class is applied
// to an ancestor element of the components (such as `body`).
.custom-dark-theme {
  @include mat.core-color($custom-dark-theme);
}

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap');

html,
body {
  height: 100%;
}
body {
  margin: 0;
  //font: $cy-body-2-m !important;
}
body * {
  box-sizing: border-box;
}

//////////////////////////////////////
// Reset angular materia components //
//////////////////////////////////////

// mat typography style reset
// .mat-body,
// .mat-body-1,
// .mat-typography .mat-body,
// .mat-typography .mat-body-1,
// .mat-typography {
//   font: $cy-body-2-m !important;
// }

.mat-table,
.mat-cell {
  font-weight: $table_cell_fontweight;
}
.mat-header-cell {
  color: $table_header_color;
  text-transform: uppercase;
}
.mat-sort-header-container {
  letter-spacing: $cy-typo-spacing-l !important;
}

// .mat-checkbox {
//   font: $cy-body-2-m !important;
// }

.mat-option {
  font: $cy-body-2-m !important;
}

// mat-checkbox style reset
.mat-checkbox-frame,
.mat-checkbox-background {
  border-color: $cy-grey-300 !important;
  border-radius: 5px !important;
}

// mat-button style-reset
.mat-button,
.mat-icon-button {
  padding: 0 10px !important;
}
.mat-button,
.mat-button-wrapper {
  display: inline-flex;
}
.mat-flat-button,
.mat-stroked-button {
  padding: 0 20px !important;
}
.mat-tab-label,
.mat-tab-link {
  min-width: 100px !important;
  color: $cy-grey-500;
  opacity: 1 !important;
  border-radius: $el-border-radius !important;
  &:focus:not(.mat-tab-disabled) {
    color: $cy-grey-700 !important;
  }
}

// mat-dialog styles reset
.mat-dialog-container {
  box-shadow: $el-elavation-1 !important;
  background: $white !important;
  border-radius: $el-border-radius !important;
  padding: 32px !important;
}

// mat-input styles reset
input.mat-input-element {
  font: inherit;
  background: transparent;
  outline: none;
  margin: 0;
  width: 100%;
  vertical-align: bottom;
  text-align: inherit;
  margin-top: 0 !important;
}

// mat-menu styles reset
.mat-menu-panel {
  min-height: 48px !important;
}

// mat-paginator styles reset
.mat-paginator {
  background-color: transparent !important;
  .mat-paginator-container {
    padding: 0;
    justify-content: flex-start;
  }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none !important;
}

.mat-paginator-range-actions .mat-icon-button {
  display: flex;
  align-items: center;
}

.paginator-nav__panel {
  .mat-select-panel {
    border-radius: $el-border-radius;
  }
}

// Global container and wrapper styles
.container {
  flex: 1;
  height: 100%;
}

.wrapper {
  flex: 1;
  height: 100%;
  &--centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.screen {
  &__wrapper {
    padding: 0px 24px;
  }
}

.content {
  &__wrapper {
    display: flex;
    padding: $wrapper-pad;
    border-radius: $wrapper_radius;
    background-color: $wrapper_bgcolor;
    min-height: $wrapper_height-min;
    max-height: $wrapper_height;
    overflow: auto;
  }
  &__container {
    display: flex;
    padding: 36px 0;
    width: 100%;
    &--center {
      justify-content: center;
    }
  }
  &__limited {
    display: flex;
    width: 100%;
    max-width: $screen_width-limited-max;
    min-width: $screen_width-limited-min;
  }
}

// Common classes
.disable {
  opacity: 0.5 !important;
  pointer-events: none;
}

.disable-tooltip {
  opacity: 0.5 !important;
  cursor: default !important;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.backdrop-blur {
  background: rgba(0, 25, 50, 0.3);
  backdrop-filter: blur(8px);
}

.pointer {
  cursor: pointer;
}

.intercom-lightweight-app-launcher,
.intercom-i4v92s {
  bottom: 7px !important;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
}
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
}

//flex classes
.flex {
  display: flex;
}

.f-1 {
  flex: 1;
}

.fd-column {
  flex-direction: column;
}

.j-start {
  justify-content: start;
}

.j-end {
  justify-content: end;
}

.j-center {
  justify-content: center;
}

.j-space-between {
  justify-content: space-between;
}

.j-space-around {
  justify-content: space-around;
}

.a-start {
  align-items: start;
}

.a-end {
  align-items: end;
}

.a-center {
  align-items: center;
}
