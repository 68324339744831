@import './color-variables.scss';
@import './typo-variables.scss';

// Shared variables
$primary: $cy-green-700;
//$secondary: $cs-nepal-100;
$accent: $cy-green-400;
$black: $cy-grey-700;
$white: $cy-white-5;
$success: $cy-green-400;
$warn: $cy-yellow-100;
$danger: $cy-red-400;

// $label-color-title: $cs-navy-60;
// $cy-green-500: $cs-navy-80;
// $label-color-text: $cs-grey-100;

// $info-color-text: $cs-blue-60;
// $instruct-color-text: $cs-nepal-100;
// $text-color-subtitle: $cs-navy-100;
// $text-color-normal: $cs-black-80;
// $text-color-light: $cs-black-60;

$link-color-text: $cy-blue-400;

// element variables
$el-bgcolor: $cy-lightGrey-40;
$el-bgcolor-light: $white;
$el-border-radius: 16px;
$el-elavation-1: 0px 14px 32px rgba(16, 47, 75, 0.04),
  0px 4px 20px rgba(16, 47, 75, 0.08);
$el-elavation-2: 0px 0px 5px rgba(0, 0, 0, 0.05);

// screen variables
$screen_width-limited-max: 600px;
$screen_width-limited-min: 400px;

// wrapper variables
$wrapper-pad: 10px 20px;
$wrapper_height: calc(100vh - 190px); // 160px + gap(15px)
$wrapper_height-min: calc(100vh - 190px);
$wrapper_tabs_height: calc(100vh - 135px);
$wrapper_tabs_content-height: calc(100vh - 208px);
$wrapper_radius: $el-border-radius;
$wrapper_bgcolor: $white;

// field varibales
$field-pad: 20px;
$field-pad-v: 10px 0;
$field-pad-h: 0 20px;
$field-mrg: 10px;

$field_height: 45px;
$field_label_color: $cy-grey-400;
$field_label_weight: 500;
$field_value_color: $black;
$field_value_weight: 500;
$field_value_weight_700: 700;
$field_option_color: $black;
$field_option_weight: 500;
$field_bordercolor: $cy-grey-300;
$field_hover: $cy-grey-50;
// $field_bgcolor: $cs-lightGrey-100;
// $field_bgcolor-light: $cs-lightGrey-40;
// $white: $white;
$field_error: $danger;

// field variables
$field_size-icon: 24px;
$field_width-icon: 45px;
$field_width-s: 150px;
$field_width-xs: 170px;
$field_width-xxs: 200px;
$field_width-xxxs: 215px;
$field_width-m: 225px;
$field_width-xm: 250px;
$field_width-xxm: 275px;
$field_width-xxxm: 300px;
$field_width-l: 325px;
$field_width-xl: 350px;
$field_width-xxl: 375px;
$field_width-xxxl: 400px;
$field_width-full: 100%;
$field_width-auto: auto;
$field_width-limited-max: $screen_width-limited-max - 60px;

// btn variables
$btn_size-icon: $field_height;

$btn_color-text-primary: $primary;
$btn_bgcolor_accent: $accent;
$btn_bgcolor_basic: $white;

$btn_width-s: 40px;
$btn_width-xs: 50px;
$btn_width-xxs: 60px;
$btn_width-xxxs: 70px;
$btn_width-m: 80px;
$btn_width-xm: 100px;
$btn_width-xxm: 120px;
$btn_width-xxxms: 130px;
$btn_width-xxxm: 140px;
$btn_width-l: 150px;
$btn_width-xl: 170px;
$btn_width-xxl: 190px;
$btn_width-xxxl: 218px;
$btn_width-full: 100%;
$btn_width-auto: auto;

// breadcrumb variables
// $nav_font-size: 24px;
// $nav_font-weight: 700;
// $nav_color: $primary;

// breadcrumb variables
$divider_gap-0: 0px;
$divider_gap-s: 5px;
$divider_gap-xs: 10px;
$divider_gap-xxs: 15px;
$divider_gap-m: 20px;
$divider_gap-xm: 25px;
$divider_gap-xxm: 30px;
$divider_gap-l: 35px;
$divider_gap-xl: 40px;
$divider_gap-xxl: 45px;

// Widget variables
$widget-padding: 30px 45px;
$widget-padding-h: 0 30px;

// toaster variables
$error_font: $cy-toaster-1-r;
$error_color: $danger;

// // Alert variables
// $alert_padding: 48px 48px;
// $alert_padding-v: 35px;
// $alert_padding-h: 48px;
// $alert_radius: $el-border-radius * 2;
// $alert_bgcolor: $white;
// $cy-head-2-b: $cs-head-2-b;
//$primary: $primary;
// $alert_title-size: $cy-typo-l;
// $cy-body-2-m: $cs-body-2-m;
// $cy-grey-700: $cs-black-60;
// $alert_text-size: $cy-typo-xxs;

// // message variables
// $message_font: $cs-title-4-r;
// $success_color: $success;
// $success_bgcolor: $success;
// $warn_color: $cs-yellow-120;
// $warn_bgcolor: $cs-yellow-10;
// $danger_color: $danger;
// $danger_bgcolor: $cs-red-10;

// // Slide toogle variables
// $toggle_bgcolor-bar: $cs-nepal-100;
// $toggle_bgcolor-thumb: $white;
// $toggle_bgcolor-checked-bar: $accent;
// $toggle_bgcolor-checked-thumb: $white;

// // Placeholder variables
// $placeholder_title-color: $cs-nepal-100;
// $placeholder_title-size: $cy-typo-r;
// $placeholder_subtitle-color: $cs-nepal-100;
// $placeholder_subtitle-size: $cy-typo-xxs;

// Text variables
$text_width-s: 40px;
$text_width-xs: 50px;
$text_width-xxs: 60px;
$text_width-xxxs: 70px;
$text_width-m: 80px;
$text_width-xm: 100px;
$text_width-xxm: 120px;
$text_width-xxxm: 140px;
$text_width-l: 150px;
$text_width-xl: 170px;
$text_width-xxl: 190px;
$text_width-xxxl: 218px;
$text_width-full: 100%;
$text_width-auto: auto;

$text_height-s: 12px;
$text_height-xs: 13px;
$text_height-xxs: 14px;
$text_height-xxxs: 15px;
$text_height-r: 16px;
$text_height-xr: 17px;
$text_height-xxr: 18px;
$text_height-xxxr: 19px;
$text_height-m: 20px;
$text_height-xm: 21px;
$text_height-xxm: 22px;
$text_height-xxxm: 23px;
$text_height-l: 24px;
$text_height-xl: 26px;
$text_height-xxl: 30px;
$text_height-xxxl: 32px;

// shape variables
$shape_size-s: 8px;

// tooltip variables
$tooltip_width-s: 10px;
$tooltip_width-xs: 11px;
$tooltip_width-xxs: 12px;
$tooltip_width-xxxs: 13px;
$tooltip_width-m: 14px;
$tooltip_width-xm: 15px;
$tooltip_width-xxm: 16px;
$tooltip_width-xxxm: 17px;
$tooltip_width-l: 18px;
$tooltip_width-xl: 19px;
$tooltip_width-xxl: 20px;
$tooltip_width-xxxl: 21px;
$tooltip_width-full: 100%;
$tooltip_width-auto: auto;
$tooltip_bgcolor: $cy-grey-700;
$tooltip_color: $white;

// // checkbox variables
// $checkbox_color-label: $text-color-subtitle;
// $checkbox_color-text: $text-color-light;
// $checkbox_color-text2: $cs-black-40;

// //org menu variables
// $dragdropmenu_wrapper-bgcolor: $cs-lightGrey-20;
// $dragdropmenu_wrapper-border-color: $cs-nepal-40;
// $dragdropmenu_wrapper-br: $cy-typo-r;
// $dragdropmenu_item-bgcolor: $cs-nepal-10;
// $dragdropmenu_item-bgcolor-drag: $cs-nepal-5;
// $dragdropmenu_item-text: $cs-navy-80;
// $dragdropmenu_item-textsize: $cy-typo-xxs;
// $dragdropmenu_title-color: $cs-black-100;
// $dragdropmenu_item_font-weight: 700;

// //card variables
// $card_para-color: $cs-nepal-80;
// $card_link-color: $cs-yellow-100;
// $card_add-color: $cs-blue-100;

// //info background variables
// $info_bg-primary: '';
// $info_bg-accent: '';
// $info_bg-warn: '';
// $info_bg-danger: '';
// $info_bg-balck: '';
// $info_bg-white: $white;
// $info_bg-default: $el-bgcolor;

// // Date picker variables
// $node_bg-color: $accent;
// $range_bg-color: $cs-yellow-60;
// //expansion panel variables
// $expansion_panel_title-color: $cs-navy-80;

// //tabs variables
// $tabs_active_color: $cs-yellow-100;

// //stepper variables
// $stepper_step-primary: $cs-navy-60;
// $stepper_step-in-progress: $cs-navy-100;
