@import './component-variables.scss';

// Shared table varibales
$table-pad: 10px 20px;

$cell-pad: 5px;
$cell-pad-t: 5px;
$cell-pad-b: 5px;
$cell-pad-r: 5px;
$cell-pad-l: 5px;
$cell-pad-h: 0 5px;
$cell-pad-v: 5px 0;

$cell-width-s: 50px;
$cell-width-xs: 65px;
$cell-width-xxs: 75px;
$cell-width-xxxs: 100px;
$cell-width-m: 115px;
$cell-width-xm: 130px;
$cell-width-xxm: 150px;
$cell-width-xxxm: 200px;
$cell-width-l: 225px;
$cell-width-xl: 250px;
$cell-width-xxl: 375px;
$cell-width-xxxl: 325px;
$cell-width-xxxxl: 425px;
$cell-width-xxxxxl: 625px;

// Table color varibales
$table_bgcolor: $white;
$table_cell_color: $cy-grey-700;
$table_cell_bgcolor: transparent;
$table_cell_link: $cy-blue-400;
$table_cell_hover: $cy-grey-50;
$table_header_color: $cy-grey-500;
$table_header_bgcolor: transparent;
$table_red_type_color: $cy-red-400;
$table_green_type_color: $cy-green-400;

// Table measure varibales
$table_height: calc(100vh - 235px);
$table_height-min: calc(100vh - 235px);
$table_height-min-tab: calc(
  100vh - 380px
); // 85px + 75px + 10px + 50px + 20px + 45px + 10px + 55px + 30px

$table_cell_fontsize: 12px;
$table_cell_fontweight: 500;
$table_header_fontsize: 12px;
$table_header_fontweight: 700;
$table_icon_fontsize: 16px;
$table_radius: $el-border-radius;
