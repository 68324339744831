// Coyards color palette

$cy-white-5: #ffffff;

$cy-grey-50: #f6f6f6;
$cy-grey-100: #eeeeee;
$cy-grey-200: #e2e2e2;
$cy-grey-300: #cbcbcb;
$cy-grey-400: #afafaf;
$cy-grey-500: #757575;
$cy-grey-600: #545454;
$cy-grey-700: #333333;

$cy-black: #000000;

$cy-lightGrey-40: #fafbfc;

$cy-green-20: #CCE7DE;
$cy-green-50: #e9f7ef;
$cy-green-100: #d3efde;
$cy-green-200: #91d6ae;
$cy-green-400: #22ad5c;
$cy-green-500: #1b8a4a;
$cy-green-700: #0e4525;

$cy-red-50: #fdf0ef;
$cy-red-100: #fadbd7;
$cy-red-200: #eeb5af;
$cy-red-400: #d44333;
$cy-red-500: #DE350B;

$cy-purple-50: #f4f1fa;

$cy-yellow-40: #ffe599;
$cy-yellow-100: #ffbe00;
$cy-yellow-150: #805f00;

$cy-blue-200: #a0bff9;
$cy-blue-400: #276ef1;
$cy-blue-600: #174291;
